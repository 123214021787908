/* Antivir */
$antivir-bg: #0094A2;
$antivir-text-color: $antivir-bg;

.antivir {
    border: 1px solid $antivir-bg;
    //background-color: $antivir-bg;
    padding: em(20);

    border-radius: 10px;

    @media (max-width: $screen-sm) {
        padding: em(20);

        .eset-link,
        .google-link {
            display: block;
            margin: 0 auto;
        }

        .eset-link {
            margin-bottom: em(10);
        }
    }
}

.antivir-text {
    font-size: em(18);
    color: $antivir-text-color;
    text-align: center;

    b,
    strong {
        font-weight: 700;
    }

    @media (max-width: $screen-sm) {

        text-align: center;

        b, strong {
            display: block;
        }
    }
}

.antivir-items {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    .eset-link {

        img {
            max-height: 40px;
        }
    }

    .google-link {

        img {
            max-height: 50px;
        }
    }

    @media (max-width: $screen-sm) {
        flex-direction: column;
    }
}
