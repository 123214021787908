.icon-external-link:before,
.icon-heart:before,
.icon-mail:before {
    content: "";
    display: inline-block;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.icon-external-link {
    opacity: .8;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        opacity: 1;
    }
}
.icon-external-link:before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy4zMDkiIGhlaWdodD0iMTcuMzA5IiB2aWV3Qm94PSIwIDAgMTcuMzA5IDE3LjMwOSI+PGRlZnM+PHN0eWxlPi5he29wYWNpdHk6MC41O30uYntmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO308L3N0eWxlPjwvZGVmcz48ZyBjbGFzcz0iYSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQgLTMuNzkzKSI+PHBhdGggY2xhc3M9ImIiIGQ9Ik0xNy45MTgsMTUuMjYydjUuMzY3YTEuNzg5LDEuNzg5LDAsMCwxLTEuNzg5LDEuNzg5SDYuMjg5QTEuNzg5LDEuNzg5LDAsMCwxLDQuNSwyMC42Mjl2LTkuODRBMS43ODksMS43ODksMCwwLDEsNi4yODksOWg1LjM2NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMS44MTYpIi8+PHBhdGggY2xhc3M9ImIiIGQ9Ik0yMi41LDQuNWg1LjM2N1Y5Ljg2NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTcuMjY1IDApIi8+PHBhdGggY2xhc3M9ImIiIGQ9Ik0xNSwxNC4zNCwyNC44NCw0LjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjIzOCAwKSIvPjwvZz48L3N2Zz4=");
}

.icon-heart:before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC4wNjQiIGhlaWdodD0iMTUuODA2IiB2aWV3Qm94PSIwIDAgMTguMDY0IDE1LjgwNiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2Y0ZjRmNDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNi4zMSwzLjMyOGE0LjgyNSw0LjgyNSwwLDAsMC02LjU4My40OGwtLjcuNzE2LS43LS43MTZhNC44MjQsNC44MjQsMCwwLDAtNi41ODMtLjQ4QTUuMDY2LDUuMDY2LDAsMCwwLDEuNCwxMC42NjNsNi44MjcsNy4wNDlhMS4xMDYsMS4xMDYsMCwwLDAsMS42LDBsNi44MjctNy4wNDlhNS4wNjMsNS4wNjMsMCwwLDAtLjM0Ni03LjMzNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAxIC0yLjI0OCkiLz48L3N2Zz4=");
    vertical-align: middle;
    opacity: .6;
}

.icon-mail:before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NDUuMjMxIiBoZWlnaHQ9IjM1NS41IiB2aWV3Qm94PSIwIDAgNDQ1LjIzMSAzNTUuNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6bm9uZTtzdHJva2U6I2ZmZjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLXdpZHRoOjZweDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4xNzggLTMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTQ2LjY4OCw2aDM0OS41YTQzLjgxNiw0My44MTYsMCwwLDEsNDMuNjg4LDQzLjY4OFYzMTEuODEzQTQzLjgxNiw0My44MTYsMCwwLDEsMzk2LjE4OCwzNTUuNUg0Ni42ODhBNDMuODE2LDQzLjgxNiwwLDAsMSwzLDMxMS44MTNWNDkuNjg4QTQzLjgxNiw0My44MTYsMCwwLDEsNDYuNjg4LDZaIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik00MzkuODc1LDksMjIxLjQzOCwxNjEuOTA2LDMsOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA0MC42ODgpIi8+PC9nPjwvc3ZnPg==");
    background-size: 400px;
}
