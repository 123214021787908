// Fonts
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,700&display=swap&subset=latin-ext');

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Comfortaa', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;


$primary:       #3A8BC6 !default;
$secondary:     #C0C0C0 !default;
$info:          #3A8BC6 !default;

$body-color: #04040D !default;;
$headings-font-weight: 300 !default;


$dark-blue: #0C133B;
$light-blue: #3A8BC6;
$grey:  #C0C0C0;
$black: #04040D;

$btn-color: $dark-blue;
$btn-bg:    $grey;
$btn-border:    $grey;
$btn-shadow:    rgba($black, 20%);

$alert-danger-border: #eb2d2d;
$alert-danger-bg: #eb2d2d;
$alert-danger-color: #ffffff;

$alert-success-border: #34bb42;
$alert-success-bg: #34bb42;
$alert-success-color: #ffffff;

$label-radio:    rgba($black, 60%);
$label-radio-hover:    $light-blue;
$label-radio-hover-border:    $light-blue;

$partner-title: #141640;
$price-title: $black;

$text-light: rgba(#f4f4f4, 60%);

$screen-xs: 380px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
