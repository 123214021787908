.jumbotron {
    color: $grey;
    background: linear-gradient($dark-blue, $light-blue) 0 0 no-repeat;
    margin-bottom: 0;
    padding: 2rem;

    @media (min-width: $screen-md) {
        min-height: 386px;
    }

    @media (max-width: $screen-sm) {
        height: 100vh;
    }
}

.jumbotron-title {
    font-size: 90px;
    text-shadow: 0 0 em(12, 90) rgba($grey, 65%);
    position: relative;
    margin-bottom: em(10, 90);

    @media (min-width: $screen-md) {
        left:  -7px;
    }

    @media (max-width: $screen-sm) {
        text-align: right;
        font-size: 80px;
    }

    @media (max-width: $screen-xs) {
        font-size: 60px;
    }
}
