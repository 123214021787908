/* Voting */
$mr-form-check-label: #f7f7f7;

.voting {
    background-color: $dark-blue;

    #categories {
        width: 100%;
    }

    .nav  {
        padding-top: em(15);
        padding-bottom: em(15);

        @media (max-width: $screen-sm) {
            flex-wrap: nowrap;
            overflow-x: scroll;

            .nav-link {
                white-space: nowrap;
            }
        }
    }

    .nav-item {
        @media (max-width: $screen-md) {
            text-align: left;
        }

        @media (max-width: $screen-sm) {
            text-align: center;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
        }

        &:before,
        &::before {
            position: relative;
            display: block;
            content: "";
            font-size: 20px;
            text-align: center;
            background: #fff;
            border-radius: 50%;
        }
    }

    .item-radio,
    input[type="radio"] {
        &:before,
        &::before {
            height: 24px;
            width: 24px;
            border: 1px solid grey;
            display: block;
            margin: 0 auto;

            @media (max-width: $screen-xs) {
                left: -7px;
                top: -6px;
            }

            .is-invalid & {
                border-color: $red;
            }
        }

        &:checked:before,
        &:checked::before {
            border-color: $light-blue;
            background-color: $light-blue;
        }

        &:checked {

            ~ .item-body {
                background-color: #ffffff;

                .item-title {
                    color: $light-blue !important;
                    font-weight: 700;
                }

                .item-border {
                    border: 2px solid $light-blue;
                    transition: border .2s ease-in-out;
                }
            }
        }
    }

    input[type="checkbox"] {
        &:before,
        &::before {
            height: 20px;
            width: 20px;
            border: 1px solid #fff;
            display: block;
            margin: 0 auto;

            .is-invalid & {
                border-color: $red;
            }
        }
    }

    .icon-external-link {
        padding-left: em(8);
    }
}


.voting-cover {
    position: relative;
    padding-top: em(100);
    padding-bottom: em(100);

    @media (max-width: $screen-sm) {
        padding-top: em(50);
        padding-bottom: em(50);
    }

    > .icon-mail {
        position: absolute;
        //z-index: 3;
        top: 150px;
        left: -180px;

        &:before {
            background-size: 436px 349px;
            width: 436px;
            height: 349px;
            opacity: .2;
        }
    }
}

.voting-form {
    position: relative;
    //z-index: 3;
}

.voting-form-fieldset {
    background-color: rgba($light-blue, 90%);
    border-radius: 30px;
    padding: em(40) em(20);

    @media (max-width: $screen-sm) {
        padding: em(40) em(20);
    }

    @media (max-width: $screen-xs) {
        padding: em(30) em(30);
    }
}

.voting-subtitle {
    font-size: 36px;
    line-height: 1.4;
    display: inline-block;
    text-transform: uppercase;
    color: $light-blue;
    position: relative;
    padding-top: 30px;
    margin-bottom: 50px;

    @media (max-width: $screen-sm) {
        //font-size: 22px;
    }

    small {
        position: absolute;
        right: -50px;
        bottom: -20px;
        font-size: 14px;
        color: rgba($black, 50%);
        text-transform: none;

        @media (max-width: $screen-sm) {
            right: auto;
            left: 20px;
        }
    }
}


.item {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.item-body {
    background-color: #ffffff;
}

.item-title {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 48px;
}

.item-border {
    border: 2px solid #ffffff;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: border .2s ease-in-out;
}

.item-img {
    border-radius: 20px;
}


.voting-form {

    .form-group {

        .form-check {
            left: 10px;
        }

        .form-check-label {
            color: $mr-form-check-label;
            display: inline;
        }
    }

    .form-control {
        background-color: #F4F4F4;
        border-radius: 37px;
    }

    .btn {
        padding: em(10) em(50);
        font-weight: 700;
        margin-top: em(10);
    }

    .invalid-feedback {
        //margin-top: 0;
    }

    .form-check-input {
        &:before,
        &::before {
            width: 20px;
            border: 1px solid #fff;
            left: -5px;
            top: -2px;

            .is-invalid {
                border-color: red;
            }
        }

        &:checked:before,
        &:checked::before {
            content: '';
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMy41MDciIGhlaWdodD0iMTYuODY2IiB2aWV3Qm94PSIwIDAgMjMuNTA3IDE2Ljg2NiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6bm9uZTtzdHJva2U6cmdiYSgxMiwxOSw1OSwwLjk5KTtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLXdpZHRoOjNweDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yNS4yNjUsOSwxMi4wMiwyMi4yNDQsNiwxNi4yMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjg3OSAtNi44NzkpIi8+PC9zdmc+");
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }
}
