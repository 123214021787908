.navbar {

    &.navbar-dark {
        background-color: $dark-blue;
    }

    .nav-link {
        font-family: "Comfortaa", sans-serif;
        font-weight: 300;

        &:focus {
            outline: none;
        }
    }

    .dropdown-item {
        color: $dark-blue;
    }
}

.navbar-brand {
    margin-right: 0;
}
