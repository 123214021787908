.partners {
    padding-top: 100px;
    padding-bottom: 100px;
}

.partner-title {
    font-size: 20px;
    margin-bottom: 50px;
}
.partner-link {

    &:hover .partner-img,
    &:focus .partner-img{
        transition: transform .3s;
        transform: scale(1.05);
    }
}

.partner-img {
    transition: transform .3s;
    max-width: 150px;
    height: auto;
}
