.neonka {
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: rgb(255, 219, 0);
    text-align: center;
}

.neonka-logo {
    max-width: 280px;
    height: auto;
}

.neonka-text {
    font-size: 16px;
}
