.hero {
    position: relative;
    z-index: 1;
    font-size: .8em;

    .lead {
        font-size: 36px;
        margin-bottom: 0;
    }

    p {
        color: rgba(#f4f4f4, 80%);
        font-size: 22px;

        @media (max-width: $screen-xs) {
            font-size: 18px;
        }
    }

    .btn {
        height: em(50);
        line-height: em(50);
        padding: 0 em(50);
        font-weight: 700;
    }

    &.btn-secondary {
        color: $dark-blue;
        box-shadow: 0 em(3) em(6) rgba(black, 16%);
    }
}


.hero-img {
    position: absolute;
    z-index: 2;
    left: em(-80);

    @media (max-width: $screen-md) {
        left: em(20);
    }
}

.hero-content {
    position: relative;
    z-index: 3;
    margin-top: em(80);

    @media (max-width: $screen-md) {
        text-align: right;
        margin-top: em(30);
        margin-right: em(30);
    }

    @media (max-width: $screen-sm) {
        .lead {
            margin-bottom: 0;
        }

        p {
            margin-top: 0;
            margin-bottom: 10px;
        }

        .btn {
            margin-top: 0;
        }
    }

    @media (max-width: 320px) {
        margin-right: 0;
        margin-top: 0;

        .lead {
            margin-bottom: 0;
        }

        .btn {
            margin-top: 0;
        }
    }
}
