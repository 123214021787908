.alert {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
    min-width: 280px;
    font-weight: 700;

    ul {
        padding-left: 10px;
    }

    @media(max-width: $screen-sm) {
        right: auto;
        width: 94%;
        margin: 0 3%;
    }
}

.alert-danger {
    border-color: $alert-danger-border;
    background-color: $alert-danger-bg;
    color: $alert-danger-color;
}

.alert-success {
    border-color: $alert-success-border;
    background-color: $alert-success-bg;
    color: $alert-success-color;
}
