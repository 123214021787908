.sponsor {
    padding-top: 50px;
}

.sponsor-title {
    font-size: 16px;
    padding-top: 2em;
    padding-bottom: 1em;
}

.sponsor-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;

    a {
        margin: 0.625em; // 10px
    }

    @media (min-width: $screen-sm) {
        // 2021
        .twentyone & {
            .motorola,
            .xiaomi {

                .img-fluid {
                    max-width: 70%;
                }
            }
        }

        .twentytwo {
            .stadlerform {
                .img-fluid {
                    max-width: 80%;
                }
            }

            .xiaomi {

                .img-fluid {
                    max-width: 85%;
                }
            }
        }
    }

    @media (max-width: $screen-sm) {
        flex-direction: column;

        .img-fluid {
            max-width: 60%;
        }
    }
}
