.price {
    padding-top: em(50);
    padding-bottom: em(50);
}

.price-title {
    font-family: "Comfortaa", sans-serif;
    font-weight: 700;
    font-size: em(22);
    line-height: 1.4;
    color: $price-title;
    margin-bottom: em(50, 22);

    strong {
        color: $light-blue;

        @media (max-width: $screen-md) {
            display: block;
            padding-top: em(5, 22);
            padding-bottom: em(5, 22);
        }
    }

    .price-total {
        background-color: $light-blue;
        color: #fff;
        padding: 5px 10px;
        border-radius: 30px;
        margin-right: 10px;
        margin-left: 10px;
        display: inline;
    }

    @media (max-width: $screen-xs) {
        font-size: 18px;
    }
}
