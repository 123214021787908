$copyright-bg: #C0C0C0;
$copy-text: #f4f4f4;
$created-by: rgba($copy-text, 60%);
$copy-link: $copy-text;

.copyright {
    background-color: $copyright-bg;
    text-align: center;
    font-size: 14px;

    a {
        color: $copy-link;
        font-weight: 700;
    }
}

.copy-text {
    color: $copy-text;
    margin-bottom: 30px;
}

.created-by {

    &,
    & a {
        color: $created-by;
        font-weight: 300;
    }
}
